var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"compta-rh-frais"},[_c('div',{staticClass:"entete"},[_c('div',{staticClass:"box-all-filter"},[_c('div',{staticClass:"box-label-champ mr-2"},[_vm._m(0),_c('b-form-select',{staticClass:"b-form-select-new-style bg-select b-form-select-new-style-w-1",attrs:{"options":_vm.ListYear},on:{"change":_vm.handleChangeYear},model:{value:(_vm.filteryear),callback:function ($$v) {_vm.filteryear=$$v},expression:"filteryear"}})],1),_c('div',{staticClass:"box-label-champ mr-2"},[_vm._m(1),_c('b-form-select',{staticClass:"b-form-select-new-style bg-select  ",attrs:{"options":[
            { value: null, text: 'Tous' } ].concat( _vm.computedGetMonthForYearTh
          ),"text-field":"text","value-field":"value"},on:{"change":_vm.handleChangeMonth},model:{value:(_vm.filterMonth),callback:function ($$v) {_vm.filterMonth=$$v},expression:"filterMonth"}})],1),_c('div',{staticClass:"box-label-champ mr-2"},[_vm._m(2),_c('b-form-select',{staticClass:"b-form-select-new-style bg-select  w-103-px",attrs:{"options":[
            { id: null, name: 'Tous' } ].concat( _vm.getOnlineFilialeInVisionFilialeFraisComptaRh
          ),"text-field":"name","value-field":"id"},on:{"change":_vm.handleFilterType},model:{value:(_vm.filterFiliale),callback:function ($$v) {_vm.filterFiliale=$$v},expression:"filterFiliale"}})],1),_c('div',{staticClass:"box-label-champ mr-2"},[_vm._m(3),_c('b-form-select',{staticClass:"b-form-select-new-style bg-select",attrs:{"options":[
            { value: null, full_name: 'Tous' } ].concat( _vm.computedValidationList
          ),"value-field":"value","text-field":"full_name"},on:{"change":_vm.handleFilterType},model:{value:(_vm.filterValidation),callback:function ($$v) {_vm.filterValidation=$$v},expression:"filterValidation"}})],1),_c('div',{staticClass:"error-message ml-2 mr-2"},[(_vm.getErrorFraisComptaRh)?_c('div',{staticClass:"error"},[(Array.isArray(_vm.getErrorFraisComptaRh))?_c('ul',{staticClass:"mb-0"},_vm._l((_vm.getErrorFraisComptaRh),function(e,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(e)+" ")])}),0):_c('div',[_vm._v(_vm._s(_vm.getErrorFraisComptaRh))])]):_vm._e()]),(_vm.getLoadingFraisComptaRh)?_c('div',{staticClass:"chargement chargement-loading-icon"},[_vm._m(4)]):_vm._e(),_c('div',{staticClass:"box-end-position"},[_c('search',{attrs:{"value":_vm.searchValue},on:{"changeSearchValue":_vm.changeSearchValue}}),(
            _vm.cantCreate(_vm.$options.name) &&
              !_vm.isSuperAdmin &&
              _vm.filterType != null &&
              !_vm.isResponsableFiliale
          )?_c('global-validation-compta-rh',{attrs:{"month":_vm.filterMonth,"year":_vm.filteryear,"type":_vm.filterType,"dataToUse":_vm.computedFraisBddCommericalCactus},on:{"reloadData":_vm.reloadData}}):_vm._e(),(_vm.computedCheckedRows && _vm.computedCheckedRows.length > 0)?_c('b-button',{staticClass:"button-export-style  ml-2 gener-avoir-button",attrs:{"size":"sm","title":"Générer des factures avoirs"},on:{"click":function($event){return _vm.uploadAvoirSelected()}}},[_c('font-awesome-icon',{attrs:{"icon":"file"}})],1):_vm._e(),_c('extraction-compta-rh',{attrs:{"filterType":_vm.filterType,"filteryear":_vm.filteryear,"filterMonth":_vm.filterMonth,"dataToUse":_vm.computedFraisBddCommericalCactus,"filter":_vm.ComputedFilter}}),(_vm.getLoadingMail)?_c('div',{staticClass:"chargement chargement-loading-icon"},[_vm._m(5)]):_vm._e(),(
            !_vm.getLoadingMail &&
              _vm.computedCheckedRows &&
              _vm.computedCheckedRows.length > 0
          )?_c('div',[(
              !_vm.isSuperAdmin &&
                _vm.cantSend(_vm.$options.name) &&
                !_vm.isResponsableFiliale
            )?_c('sendMailComptaRh',{attrs:{"dataToUse":_vm.computedFraisBddCommericalCactus}}):_vm._e()],1):_vm._e(),(
            _vm.filterType != null &&
              !_vm.isSuperAdmin &&
              _vm.cantCreate(_vm.$options.name) &&
              !_vm.isResponsableFiliale
          )?_c('add-facture-compta-rh',{attrs:{"type":_vm.filterType,"month":_vm.filterMonth,"year":_vm.filteryear},on:{"reloadData":_vm.reloadFactureData}}):_vm._e()],1)])]),_c('div',{staticClass:"body-box-rapport "},[_c('div',{staticClass:"table-rapport-style w-100-p"},[_c('b-table',{staticClass:"custom-table-style customTbaleFraisComptaRh table-header",attrs:{"show-empty":"","id":"my-table","items":_vm.computedFraisBddCommericalCactus,"fields":_vm.computedFields,"bordered":"","sticky-header":"","hover":"","responsive":"","head-variant":"light","tbody-tr-class":_vm.rowClass,"empty-text":"Il n'y a aucun enregistrement à afficher"},scopedSlots:_vm._u([{key:"head()",fn:function(data){return [_c('div',[(
                data.field.key != 'check_all' &&
                  data.field.key != 'installeur' &&
                  data.field.key != 'filiale_support' &&
                  data.field.key != 'month'
              )?_c('span',{class:{
                'white-color': _vm.computedHeaderVisibility(data.field.key)
              }},[(data.field.key == 'support')?[_vm._v(_vm._s(_vm.filterType == null ? 'Montant HT' : _vm.filterType == 'GE COMPTA' ? 'Montant HT GE COMPTA' : 'Montant HT GE RH'))]:_vm._e(),(data.field.key == 'statue')?[_vm._v(_vm._s(_vm.filterType == null ? 'Statut Facture' : _vm.filterType == 'GE COMPTA' ? 'Statut GE COMPTA' : 'Statut GE RH'))]:_vm._e(),(data.field.key == 'date')?[_vm._v(_vm._s(_vm.filterType == null ? 'Date Facture' : _vm.filterType == 'GE COMPTA' ? 'Date GE COMPTA' : 'Date GE RH'))]:_vm._e(),(data.field.key == 'montant_ttc')?[_vm._v(_vm._s(_vm.filterType == null ? 'Montant TTC' : _vm.filterType == 'GE COMPTA' ? 'Montant TTC GE COMPTA' : 'Montant TTC GE RH'))]:_vm._e(),(data.field.key == 'num_facture')?[_vm._v(_vm._s(data.field.label))]:_vm._e(),(data.field.key == 'statue_installateur')?[_vm._v(_vm._s(data.field.label))]:_vm._e()],2):_c('div',[(
                  data.field.key == 'installeur' ||
                    data.field.key == 'filiale_support' ||
                    data.field.key == 'month'
                )?[_vm._v(" "+_vm._s(data.field.label)+" ")]:[_c('b-form-checkbox',{staticClass:"check-th",attrs:{"value":true,"unchecked-value":false},on:{"change":_vm.checkAllFunction},model:{value:(_vm.checkAll),callback:function ($$v) {_vm.checkAll=$$v},expression:"checkAll"}})]],2)])]}},{key:"cell(check_all)",fn:function(data){return [_c('b-form-checkbox',{staticClass:"check-th",attrs:{"value":true,"unchecked-value":false},on:{"change":function($event){return _vm.checkAllVerif(data.item.check, data)}},model:{value:(data.item.check),callback:function ($$v) {_vm.$set(data.item, "check", $$v)},expression:"data.item.check"}})]}},{key:"cell(month)",fn:function(data){return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(data.item.month)+" ")])]}},{key:"cell(installeur)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.filiale && data.item.filiale.name ? data.item.filiale.name : '')+" ")]}},{key:"cell(filiale_support)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.type)+" ")]}},{key:"cell(montant_ttc)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.montant_ttc)+" € ")]}},{key:"cell(support)",fn:function(data){return [_c('div',{staticClass:"block-service-style"},[_c('span',{staticClass:"default-color-statue",class:{
                'red-color': data.item.statue == 'facture non validée',
                'green-color': data.item.statue == 'paye',
                'red-color montant-bare': data.item.statue == 'annulee'
              }},[_c('EditableInput',{attrs:{"editable":_vm.cantUpdate(_vm.$options.name) &&
                    !_vm.isSuperAdmin &&
                    !_vm.isResponsableFiliale &&
                    data.item.statue != 'paye' &&
                    data.item.statue != 'annulee',"champName":"montant","item":data.item,"value":data.item.montant,"type":"text","updateFunction":_vm.updateStatueFraisComptaRh,"showIconEdit":true,"editableType":"input","defaultColor":true,"uniteMontant":" €"}})],1),_c('template-compta-rh',{attrs:{"componentName":_vm.$options.name,"year":_vm.filteryear,"month":_vm.filterMonth,"item":data.item,"type":_vm.filterType,"editable":data.item.statue == 'paye' || data.item.statue == 'annulee'
                  ? false
                  : true,"download":data.item.valid}})],1)]}},{key:"cell(statue)",fn:function(data){return [_c('EditableInput',{attrs:{"editable":_vm.isSuperAdmin ||
                (_vm.cantUpdate(_vm.$options.name) &&
                  !_vm.isSuperAdmin &&
                  !_vm.isResponsableFiliale &&
                  data.item.statue != 'paye' &&
                  data.item.statue != 'annulee'),"champName":"statue","editableType":"select","optionsSelect":_vm.computedValidationList,"item":data.item,"value":data.item.statue,"updateFunction":_vm.updateStatueFraisComptaRh,"label":"value","positionSelect":true,"formate":"regieFacture","showIconEdit":true}})]}},{key:"cell(statue_installateur)",fn:function(data){return [_c('div',{class:{
              'block-service-style': data.item.responsable_installateur_email
            }},[(data.item.valid == true)?_c('EditableInput',{attrs:{"editable":data.item.valid == true &&
                  (_vm.isSuperAdmin ||
                    (_vm.cantUpdate(_vm.$options.name) &&
                      _vm.isResponsableFiliale &&
                      data.item.statue != 'paye' &&
                      data.item.statue != 'annulee' &&
                      data.item.statue_installateur != 'paye')),"champName":"statue_installateur","editableType":"select","optionsSelect":_vm.computedValidationListInstallateur,"item":data.item,"value":data.item.statue_installateur,"updateFunction":_vm.updateStatueInstallateurFraisComptaRh,"label":"value","positionSelect":true,"formate":"regieFacture","showIconEdit":true}}):_vm._e(),_c('icon-info-component',{attrs:{"item":data.item.responsable_installateur_email}})],1)]}},{key:"cell(date)",fn:function(data){return [_c('EditableInput',{attrs:{"editable":_vm.cantUpdate(_vm.$options.name) && !_vm.isSuperAdmin && false,"champName":"date","item":data.item,"value":data.item.date,"type":"date","editableType":"datetime","updateFunction":_vm.updateStatueFraisComptaRh,"showIconEdit":true}})]}},{key:"cell(actions)",fn:function(data){return [(
              _vm.cantDelete(_vm.$options.name) &&
                data.item.valid != true &&
                !_vm.isSuperAdmin &&
                !_vm.isResponsableFiliale
            )?_c('font-awesome-icon',{staticClass:"trash-style",attrs:{"title":"Supprimer la facture","icon":"trash"},on:{"click":function($event){return _vm.handleDeleteModal(data.item)}}}):_vm._e()]}}])}),_c('div',{staticClass:"footer-style mt-2"},[_c('b-pagination',{staticClass:"pagination-style",attrs:{"total-rows":_vm.geTtotalRowComptaRhTh,"per-page":_vm.perPage,"aria-controls":"my-table","pills":"","align":"center","size":"sm"},on:{"change":_vm.pagination},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('div',{staticClass:"per-page-element-style"},[_vm._m(6),_c('b-form-select',{staticClass:"b-form-select-new-style bg-select",attrs:{"options":_vm.perPageList},on:{"change":_vm.changePerPage},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1)],1)],1),(_vm.computedtotalht > 0)?_c('div',{staticClass:"style-somme-th-ttc"},[_c('p',{staticClass:"m-2"},[_vm._v(" Total ht: "+_vm._s(_vm.computedtotalht.toFixed(2).toLocaleString('en-US'))+"€ "+_vm._s(' ')+"Total ttc: "+_vm._s((_vm.computedtotalht * 1.2).toFixed(2).toLocaleString('en-US'))+"€ ")])]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"label-box-style label-box-style-w-1 p-4-5"},[_c('span',{staticClass:"title-tabel"},[_vm._v("Année")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"label-box-style  w-46-px"},[_c('span',{staticClass:"title-tabel"},[_vm._v("Mois")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"label-box-style  w-93-px"},[_c('span',{staticClass:"title-tabel"},[_vm._v("Filiale Installateur")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"label-box-style   w-46-px"},[_c('span',{staticClass:"title-tabel"},[_vm._v("Statut")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"spinner-border",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"spinner-border",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box-label-champ"},[_c('div',{staticClass:"label-box-style"},[_c('span',{staticClass:"title-tabel"},[_vm._v("Eléments par page")])])])}]

export { render, staticRenderFns }