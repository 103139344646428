<template>
  <div class="compta-rh-frais">
    <div class="entete">
      <div class="box-all-filter">
        <div class="box-label-champ mr-2">
          <div class="label-box-style label-box-style-w-1 p-4-5">
            <span class="title-tabel">Année</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select b-form-select-new-style-w-1"
            v-model="filteryear"
            :options="ListYear"
            @change="handleChangeYear"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  w-46-px">
            <span class="title-tabel">Mois</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select  "
            v-model="filterMonth"
            :options="[
              { value: null, text: 'Tous' },
              ...computedGetMonthForYearTh
            ]"
            @change="handleChangeMonth"
            text-field="text"
            value-field="value"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  w-93-px">
            <span class="title-tabel">Filiale Installateur</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select  w-103-px"
            v-model="filterFiliale"
            :options="[
              { id: null, name: 'Tous' },
              ...getOnlineFilialeInVisionFilialeFraisComptaRh
            ]"
            text-field="name"
            value-field="id"
            @change="handleFilterType"
          ></b-form-select>
        </div>
        <!-- <div class="box-label-champ mr-2" v-if="computedRole">
          <div class="label-box-style  w-78-px">
            <span class="title-tabel">Filiale Support</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select  w-78-px"
            v-model="filterType"
            :options="[{ value: null, text: 'Tous' }, ...computedListType]"
            text-field="text"
            value-field="value"
            @change="handleFilter"
          ></b-form-select>
        </div> -->
        <div class="box-label-champ mr-2">
          <div class="label-box-style   w-46-px">
            <span class="title-tabel">Statut</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select"
            v-model="filterValidation"
            :options="[
              { value: null, full_name: 'Tous' },
              ...computedValidationList
            ]"
            value-field="value"
            text-field="full_name"
            @change="handleFilterType"
          ></b-form-select>
        </div>
        <div class="error-message ml-2 mr-2">
          <div v-if="getErrorFraisComptaRh" class="error">
            <ul v-if="Array.isArray(getErrorFraisComptaRh)" class="mb-0">
              <li v-for="(e, index) in getErrorFraisComptaRh" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getErrorFraisComptaRh }}</div>
          </div>
        </div>
        <div
          v-if="getLoadingFraisComptaRh"
          class="chargement chargement-loading-icon"
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="box-end-position">
          <search :value="searchValue" @changeSearchValue="changeSearchValue" />
          <global-validation-compta-rh
            v-if="
              cantCreate($options.name) &&
                !isSuperAdmin &&
                filterType != null &&
                !isResponsableFiliale
            "
            :month="filterMonth"
            :year="filteryear"
            :type="filterType"
            @reloadData="reloadData"
            :dataToUse="computedFraisBddCommericalCactus"
          />
          <b-button
            v-if="computedCheckedRows && computedCheckedRows.length > 0"
            size="sm"
            class="button-export-style  ml-2 gener-avoir-button"
            title="Générer des factures avoirs"
            @click="uploadAvoirSelected()"
          >
            <font-awesome-icon icon="file" />
          </b-button>
          <extraction-compta-rh
            :filterType="filterType"
            :filteryear="filteryear"
            :filterMonth="filterMonth"
            :dataToUse="computedFraisBddCommericalCactus"
            :filter="ComputedFilter"
          />
          <div v-if="getLoadingMail" class="chargement chargement-loading-icon">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div
            v-if="
              !getLoadingMail &&
                computedCheckedRows &&
                computedCheckedRows.length > 0
            "
          >
            <sendMailComptaRh
              v-if="
                !isSuperAdmin &&
                  cantSend($options.name) &&
                  !isResponsableFiliale
              "
              :dataToUse="computedFraisBddCommericalCactus"
            />
          </div>
          <add-facture-compta-rh
            :type="filterType"
            :month="filterMonth"
            :year="filteryear"
            @reloadData="reloadFactureData"
            v-if="
              filterType != null &&
                !isSuperAdmin &&
                cantCreate($options.name) &&
                !isResponsableFiliale
            "
          />
        </div>
      </div>
    </div>
    <div class="body-box-rapport ">
      <div class="table-rapport-style w-100-p">
        <b-table
          show-empty
          id="my-table"
          class="custom-table-style customTbaleFraisComptaRh table-header"
          :items="computedFraisBddCommericalCactus"
          :fields="computedFields"
          bordered
          sticky-header
          hover
          responsive
          head-variant="light"
          :tbody-tr-class="rowClass"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template v-slot:head()="data">
            <div>
              <span
                v-if="
                  data.field.key != 'check_all' &&
                    data.field.key != 'installeur' &&
                    data.field.key != 'filiale_support' &&
                    data.field.key != 'month'
                "
                :class="{
                  'white-color': computedHeaderVisibility(data.field.key)
                }"
              >
                <template v-if="data.field.key == 'support'">{{
                  filterType == null
                    ? 'Montant HT'
                    : filterType == 'GE COMPTA'
                    ? 'Montant HT GE COMPTA'
                    : 'Montant HT GE RH'
                }}</template>
                <template v-if="data.field.key == 'statue'">{{
                  filterType == null
                    ? 'Statut Facture'
                    : filterType == 'GE COMPTA'
                    ? 'Statut GE COMPTA'
                    : 'Statut GE RH'
                }}</template>
                <template v-if="data.field.key == 'date'">{{
                  filterType == null
                    ? 'Date Facture'
                    : filterType == 'GE COMPTA'
                    ? 'Date GE COMPTA'
                    : 'Date GE RH'
                }}</template>
                <template v-if="data.field.key == 'montant_ttc'">{{
                  filterType == null
                    ? 'Montant TTC'
                    : filterType == 'GE COMPTA'
                    ? 'Montant TTC GE COMPTA'
                    : 'Montant TTC GE RH'
                }}</template>
                <template v-if="data.field.key == 'num_facture'">{{
                  data.field.label
                }}</template>
                <template v-if="data.field.key == 'statue_installateur'">{{
                  data.field.label
                }}</template>
              </span>
              <div v-else>
                <template
                  v-if="
                    data.field.key == 'installeur' ||
                      data.field.key == 'filiale_support' ||
                      data.field.key == 'month'
                  "
                >
                  {{ data.field.label }}
                </template>
                <template v-else>
                  <b-form-checkbox
                    v-model="checkAll"
                    @change="checkAllFunction"
                    class="check-th"
                    :value="true"
                    :unchecked-value="false"
                  >
                  </b-form-checkbox>
                </template>
              </div>
            </div>
          </template>
          <template v-slot:cell(check_all)="data">
            <b-form-checkbox
              v-model="data.item.check"
              @change="checkAllVerif(data.item.check, data)"
              class="check-th"
              :value="true"
              :unchecked-value="false"
            >
            </b-form-checkbox>
          </template>
          <template v-slot:cell(month)="data"
            ><span class="text-capitalize"
              >{{ data.item.month }}
            </span></template
          >
          <template v-slot:cell(installeur)="data">
            {{
              data.item.filiale && data.item.filiale.name
                ? data.item.filiale.name
                : ''
            }}
          </template>
          <template v-slot:cell(filiale_support)="data">
            {{ data.item.type }}
          </template>
          <template v-slot:cell(montant_ttc)="data">
            {{ data.item.montant_ttc }} €
          </template>
          <template v-slot:cell(support)="data">
            <div class="block-service-style">
              <span
                class="default-color-statue"
                :class="{
                  'red-color': data.item.statue == 'facture non validée',
                  'green-color': data.item.statue == 'paye',
                  'red-color montant-bare': data.item.statue == 'annulee'
                }"
              >
                <EditableInput
                  :editable="
                    cantUpdate($options.name) &&
                      !isSuperAdmin &&
                      !isResponsableFiliale &&
                      data.item.statue != 'paye' &&
                      data.item.statue != 'annulee'
                  "
                  champName="montant"
                  :item="data.item"
                  :value="data.item.montant"
                  type="text"
                  :updateFunction="updateStatueFraisComptaRh"
                  :showIconEdit="true"
                  editableType="input"
                  :defaultColor="true"
                  uniteMontant=" €"
              /></span>

              <template-compta-rh
                :componentName="$options.name"
                :year="filteryear"
                :month="filterMonth"
                :item="data.item"
                :type="filterType"
                :editable="
                  data.item.statue == 'paye' || data.item.statue == 'annulee'
                    ? false
                    : true
                "
                :download="data.item.valid"
              />
            </div>
          </template>
          <template v-slot:cell(statue)="data">
            <EditableInput
              :editable="
                isSuperAdmin ||
                  (cantUpdate($options.name) &&
                    !isSuperAdmin &&
                    !isResponsableFiliale &&
                    data.item.statue != 'paye' &&
                    data.item.statue != 'annulee')
              "
              champName="statue"
              editableType="select"
              :optionsSelect="computedValidationList"
              :item="data.item"
              :value="data.item.statue"
              :updateFunction="updateStatueFraisComptaRh"
              label="value"
              :positionSelect="true"
              formate="regieFacture"
              :showIconEdit="true"
            />
          </template>
          <template v-slot:cell(statue_installateur)="data">
            <div
              :class="{
                'block-service-style': data.item.responsable_installateur_email
              }"
            >
              <EditableInput
                v-if="data.item.valid == true"
                :editable="
                  data.item.valid == true &&
                    (isSuperAdmin ||
                      (cantUpdate($options.name) &&
                        isResponsableFiliale &&
                        data.item.statue != 'paye' &&
                        data.item.statue != 'annulee' &&
                        data.item.statue_installateur != 'paye'))
                "
                champName="statue_installateur"
                editableType="select"
                :optionsSelect="computedValidationListInstallateur"
                :item="data.item"
                :value="data.item.statue_installateur"
                :updateFunction="updateStatueInstallateurFraisComptaRh"
                label="value"
                :positionSelect="true"
                formate="regieFacture"
                :showIconEdit="true"
              />
              <icon-info-component
                :item="data.item.responsable_installateur_email"
              />
            </div>
          </template>
          <template v-slot:cell(date)="data">
            <EditableInput
              :editable="cantUpdate($options.name) && !isSuperAdmin && false"
              champName="date"
              :item="data.item"
              :value="data.item.date"
              type="date"
              editableType="datetime"
              :updateFunction="updateStatueFraisComptaRh"
              :showIconEdit="true"
            />
          </template>
          <template v-slot:cell(actions)="data">
            <font-awesome-icon
              v-if="
                cantDelete($options.name) &&
                  data.item.valid != true &&
                  !isSuperAdmin &&
                  !isResponsableFiliale
              "
              title="Supprimer la facture"
              icon="trash"
              class="trash-style"
              @click="handleDeleteModal(data.item)"
            />
          </template>
        </b-table>
        <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="geTtotalRowComptaRhTh"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>
      <div v-if="computedtotalht > 0" class="style-somme-th-ttc">
        <p class="m-2">
          Total ht:
          {{ computedtotalht.toFixed(2).toLocaleString('en-US') }}€
          {{ '   ' }}Total ttc:

          {{ (computedtotalht * 1.2).toFixed(2).toLocaleString('en-US') }}€
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import EditableInput from '../../component/EditableInput.vue';
import GlobalValidationComptaRh from './component/comptaRh/globalValidationComptaRh.vue';
import ExtractionComptaRh from './component/comptaRh/extractionComptaRh.vue';
import TemplateComptaRh from './component/comptaRh/templateComptaRh.vue';
export default {
  name: 'Gestion-Compta',
  data() {
    return {
      page: 1,
      perPage: 50,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ],
      filteryear: null,
      ListYear: [],
      filterMonth: null,
      filterType: 'GE COMPTA',
      filterFiliale: null,
      filterValidation: null,
      fields: [
        {
          key: 'check_all',
          label: '',
          tdClass: 'check-column-frais',
          thClass: 'th-check-column-frais'
        },
        {
          key: 'installeur',
          label: 'Filiale Installateur',
          thClass: 'width-fixed',
          tdClass: 'width-fixed'
        },
        {
          key: 'month',
          label: 'Mois',
          thClass: 'width-fixed',
          tdClass: 'width-fixed'
        },
        {
          key: 'filiale_support',
          label: 'Filiale support',
          thClass: 'width-fixed',
          tdClass: 'width-fixed'
        },
        {
          key: 'support',
          label: 'Support Filiale',
          thClass:
            this.filterType == 'GE RH'
              ? 'bg-color-th-secretaiat'
              : 'bg-color-th-comptabilite'
        },
        {
          key: 'montant_ttc',
          label: 'Montant TTC',
          thClass:
            this.filterType == 'GE RH'
              ? 'bg-color-th-secretaiat'
              : 'bg-color-th-comptabilite'
        },

        {
          key: 'num_facture',
          label: 'N° Facture',
          thClass:
            this.filterType == 'GE RH'
              ? 'bg-color-th-secretaiat'
              : 'bg-color-th-comptabilite'
        },
        {
          key: 'statue',
          label: 'Statut',
          thClass:
            this.filterType == 'GE RH'
              ? 'bg-color-th-secretaiat'
              : 'bg-color-th-comptabilite'
        },
        {
          key: 'statue_installateur',
          label: 'Statut de paiement installateur',
          thClass:
            this.filterType == 'GE RH'
              ? 'bg-color-th-secretaiat'
              : 'bg-color-th-comptabilite'
        },
        {
          key: 'date',
          label: 'Date',
          thClass:
            this.filterType == 'GE RH'
              ? 'bg-color-th-secretaiat'
              : 'bg-color-th-comptabilite'
        },
        {
          key: 'actions',
          label: '',
          thClass: 'width-fixed',
          tdClass: 'width-fixed'
        }
      ],
      listType: [
        { value: 'GE COMPTA', text: 'GE COMPTA' },
        { value: 'GE RH', text: 'GE RH' }
      ],
      sortBy: 'nom',
      searchValue: null,
      checkAll: false
    };
  },
  computed: {
    ...mapGetters([
      'getMonthForYearTh',
      'getLoadingFraisComptaRh',
      'getFraisComptaRh',
      'cantUpdate',
      'getErrorFraisComptaRh',
      'cantCreate',
      'getUserData',
      'cantDelete',
      'getOnlineFilialeInVisionFilialeFraisComptaRh',
      'cantSend',
      'getLoadingMail',
      'getFraisComptaRh',
      'isResponsableFiliale',
      'geTtotalRowComptaRhTh'
    ]),
    ComputedFilter() {
      return {
        year: this.filteryear,
        month: this.filterMonth,
        type: this.filterType,
        search: this.searchValue,
        filiale: this.filterFiliale,
        statue: this.filterValidation
      };
    },
    computedCheckedRows() {
      return this.getFraisComptaRh?.filter(item => item.check == true);
    },
    computedtotalht() {
      let sum = 0;
      this.computedCheckedRows.map(item => {
        sum += item.montant;
      });
      return Math.round(sum * 100) / 100;
    },
    computedtotalTtc() {
      let sum = 0;
      this.computedCheckedRows.map(item => {
        sum += item.montant_ttc;
      });
      return Math.round(sum * 100) / 100;
    },
    computedValidationList() {
      return [
        { value: 'a paye', full_name: 'Facture à payer' },
        { value: 'annulee', full_name: 'Facture annulée' },
        { value: 'paye', full_name: 'Facture payée' },
        {
          value: 'facture non validée',
          full_name: 'Facture non validée',
          $isDisabled: true
        }
      ];
    },
    computedValidationListInstallateur() {
      return [
        { value: 'a paye', full_name: 'Facture à payer' },
        { value: 'paye', full_name: 'Facture payée' }
      ];
    },
    isSuperAdmin() {
      return this.getUserData?.role && this.getUserData.role == 'admin';
    },
    computedRole() {
      if (
        this.getUserData &&
        this.getUserData.role &&
        (this.getUserData.role == 'admin' ||
          this.getUserData.role == 'responsable_ge_rh_compta')
      ) {
        return true;
      }
      return false;
    },
    computedListType() {
      return this.listType;
    },
    computedGetMonthForYearTh() {
      const tab = this.getMonthForYearTh.map(item => ({
        value: item.month_name,
        text: item.month_name
      }));
      return tab;
    },
    computedFraisBddCommericalCactus() {
      return this.getFraisComptaRh;
    },
    computedFields() {
      if (this.filterType != null) {
        return this.fields.filter(item => item.key != 'filiale_support');
      }
      return this.fields.filter(item => item.key != 'actions');
    },
    computedHeaderVisibility() {
      return function(data) {
        if (
          data != 'check_all' &&
          data != 'installeur' &&
          data != 'filiale_support' &&
          data != 'month'
        ) {
          return true;
        } else {
          return false;
        }
      };
    }
  },
  methods: {
    ...mapActions([
      'fetchMonthForYearTH',
      'fetchFraisComptaRh',
      'updateStatueFraisComptaRh',
      'refreshDataBddCommericalCactusCommercialCactus',
      'deleteFactureComptaRhActions',
      'getAllOnlineFilialeInFraisVisionFilialeComptaRh',
      'updateStatueInstallateurFraisComptaRh',
      'generateAvoir'
    ]),
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
    },
    changePerPage() {
      this.page = 1;
      this.handleFilter();
    },
    // checkRole() {
    //   if (this.getUserData && this.getUserData.role) {
    //     switch (this.getUserData.role) {
    //       case 'responsable_ge_rh':
    //         this.filterType = 'GE RH';
    //         break;
    //       case 'responsable_ge_compta':
    //         this.filterType = 'GE COMPTA';
    //         break;
    //     }
    //   }
    // },
    handleChangeYear() {
      this.page = 1;
      this.filterMonth = null;
      this.filterValidation = null;
      this.filterFiliale = null;
      this.fetchMonthForYearTH(this.filteryear);
      this.getFilialeOnline();
      this.handleFilter();
      this.setLocalStorageFraiThCompta();
    },
    handleChangeMonth() {
      this.page = 1;
      this.filterValidation = null;
      this.filterFiliale = null;
      this.getFilialeOnline();
      this.handleFilter();
      this.setLocalStorageFraiThCompta();
    },
    handleFilterType() {
      this.page = 1;
      this.getFilialeOnline();
      this.handleFilter();
      this.setLocalStorageFraiThCompta();
    },
    getFilialeOnline() {
      this.getAllOnlineFilialeInFraisVisionFilialeComptaRh({
        year: this.filteryear,
        month: this.filterMonth,
        type: this.filterType,
        statue: this.filterValidation
      });
    },
    handleFilter() {
      this.checkAll = false;
      this.fetchFraisComptaRh({
        page: this.page,
        per_page: this.perPage,
        year: this.filteryear,
        month: this.filterMonth,
        type: this.filterType,
        search: this.searchValue,
        filiale: this.filterFiliale,
        statue: this.filterValidation
      });
    },
    reloadData() {
      this.checkAll = false;
      this.filterValidation = null;
      this.handleFilter();
    },
    reloadFactureData(data) {
      this.filteryear = data.year;
      this.filterMonth = data.month;
      this.fetchMonthForYearTH(this.filteryear);
      this.reloadData();
    },
    changeSearchValue(value) {
      this.searchValue = value;
      this.handleFilter();
      this.setLocalStorageFraiThCompta();
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      return 'ligneEnCourDeValidation';
    },
    async refreshBddCommericalCactus() {
      const response = await this.refreshDataBddCommericalCactusCommercialCactus(
        {
          year: this.filteryear,
          month: this.filterMonth
        }
      );
      if (response) {
        this.handleFilter();
      }
    },
    uploadAvoirSelected() {
      this.$confirm(
        '',
        'Êtes-vous sûr de vouloir générer des avoirs pour ces factures (avec statut annulé) ? ',
        'warning'
      )
        .then(async () => {
          let data = this.getFraisComptaRh
            .filter(function(item) {
              return item.check == true;
            })
            .map(function(item) {
              return item.id;
            });

          const response = await this.generateAvoir(data);
        })
        .catch(error => {
          // this.checkAll = false;
          // this.checkAllFunction();
        });
    },
    checkAllFunction() {
      this.getFraisComptaRh.map(item => (item.check = this.checkAll));
    },
    checkAllVerif(item, item1) {
      if (item == false && this.checkAll == true) {
        this.checkAll = false;
      }
    },
    handleDeleteModal(item) {
      this.$confirm(
        '',
        'Êtes-vous sur de vouloir supprimer cette facture ?',
        'warning'
      ).then(async () => {
        const response = await this.deleteFactureComptaRhActions(item.id);
        if (response.succes) {
          this.$alert('', 'Facture supprimer', 'success');
        } else {
          this.$alert('', response.error, 'error');
        }
      });
    },

    setLocalStorageFraiThCompta() {
      localStorage.setItem(
        'frais-th-compta',
        JSON.stringify({
          year: this.filteryear,
          month: this.filterMonth,
          type: this.filterType,
          search: this.searchValue,
          statue: this.filterValidation,
          filiale: this.filterFiliale
        })
      );
    }
  },
  components: {
    EditableInput,
    search: () => import('@/views/component/SearchInputComponent.vue'),
    GlobalValidationComptaRh,
    ExtractionComptaRh,
    AddFactureComptaRh: () =>
      import('./component/comptaRh/addFactureComptaRh.vue'),
    TemplateComptaRh,
    sendMailComptaRh: () => import('./component/comptaRh/sendMailComptaRh.vue'),
    iconInfoComponent: () => import('./component/iconInfoComponent.vue')
  },
  async mounted() {
    var courantYear = moment().year();
    this.filteryear = courantYear;
    if (localStorage.getItem('frais-th-compta')) {
      this.filteryear = JSON.parse(
        localStorage.getItem('frais-th-compta')
      ).year;
    }
    await this.fetchMonthForYearTH(this.filteryear);
    var courantMonthId = moment().month();
    var courantMonth = this.getMonthForYearTh[courantMonthId]
      ? this.getMonthForYearTh[courantMonthId].month_name
      : this.filterMonth;

    if (localStorage.getItem('frais-th-compta')) {
      this.filterMonth = JSON.parse(
        localStorage.getItem('frais-th-compta')
      ).month;
      this.filterType = JSON.parse(
        localStorage.getItem('frais-th-compta')
      ).type;
      this.searchValue = JSON.parse(
        localStorage.getItem('frais-th-compta')
      ).search;
      this.filterValidation = JSON.parse(
        localStorage.getItem('frais-th-compta')
      ).statue;
      this.filterFiliale = JSON.parse(
        localStorage.getItem('frais-th-compta')
      ).filiale;
    } else {
      this.filteryear = courantYear;
      this.filterMonth = courantMonth;
      this.setLocalStorageFraiThCompta();
    }

    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }

    // this.checkRole();
    this.getFilialeOnline();
    this.handleFilter();
  }
};
</script>
<style scoped lang="scss">
.compta-rh-frais {
  .entete {
    padding: 5px 10px;
  }
  .body-box-rapport {
    height: calc(100vh - 174px);
    .customTbaleFraisComptaRh {
      max-height: calc(100vh - 230px) !important;
      height: calc(100vh - 230px) !important;
      margin-bottom: 0px;
    }
    .icon-plus {
      margin-right: 7px;
      color: #ffff;
      font-size: 10px;
      &:hover {
        color: #8d8cb7;
      }
    }
    .trash-style {
      color: #dc3545;
      outline: none;
      font-size: 13px;
    }
  }
  .block-service-style {
    display: flex;
    justify-content: space-between;
    text-align: left;
  }
  .style-somme-th-ttc {
    font-family: 'Montserrat', sans-serif;
    background-color: #d0e4f5;
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: auto;
    height: 35px;
    border-radius: 30px;
    z-index: 120;
  }
}
.gener-avoir-button {
  padding: 1px 11px;
  font-size: 11px;
  height: 28px;
}
</style>
<style lang="scss">
.table-rapport-style .customTbaleFraisComptaRh {
  .item-details-style {
    margin: 2px 0px;
  }
  td,
  th {
    font-size: 9px;
    margin: 2px 0px;
    min-width: 105px;
  }
  .width-fixed {
    min-width: 80px;
  }
}
</style>
